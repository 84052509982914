<template>
    <div>
        <v-btn
            outlined
            class="ma-2"
            @click="importExcel"
        >
            {{ textButton ?? $t('common.importExcel') }}
        </v-btn>

        <input
            ref="file"
            type="file"
            hidden
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            @change="seletedFileToImport"
        >
    </div>

</template>

<script>
import axios from "@/plugins/axios";

export default {
    name: "ImportFile",
    props: {
        urlSubmit: {
            type: String
        },
        callbackOnImport: {
            type: Function
        },
        textButton: {
            type: String
        }
    },
    methods: {
        async seletedFileToImport(event) {
            try {
                if (event.target.files && event.target.files.length) {
                    this.$store.dispatch('loading/start');

                    const file = event.target.files[0];

                    var formData = new FormData();
                    formData.append("file", file);
                    /* const res = */
                    await axios.post(this.urlSubmit, formData, {
                        timeout: 300000,
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    if (this.callbackOnImport)
                        await this.callbackOnImport();

                }
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.accionError'))
            }

            this.$store.dispatch('loading/end');

        },
        async importExcel() {
            const fileInputElement = this.$refs.file;
            fileInputElement.click();
        }
    }
}
</script>
